<template>
  <v-card
    class="pa-2 d-flex flex-column justify-space-between"
    min-height="500"
  >
    <v-card flat class="pa-0 d-flex">
      <v-icon left color="black">fa-unlock</v-icon>
      <v-card-title class="text-start mx-auto font-weight-bold text-h4">
        {{ $t("site.access.login") }}
      </v-card-title>
      <v-spacer></v-spacer>
    </v-card>

    <v-card flat class="pa-0">
      <v-container>
        <v-form class="my-4">
          <v-text-field
            :label="$t('site.access.username')"
            v-model="user.username"
            @keydown.enter="loginUser"
            color="primary"
            outlined
            clearable
            clear-icon="mdi-close-circle-outline"
          />

          <v-text-field
            :label="$t('site.access.password')"
            v-model="user.password"
            color="primary"
            :append-icon="passwordIcon"
            :type="showPasswordText ? 'text' : 'password'"
            outlined
            clearable
            @keydown.enter="loginUser"
            clear-icon="mdi-close-circle-outline"
            @click:append="showPasswordText = !showPasswordText"
          />
        </v-form>
      </v-container>
    </v-card>
    <v-card-actions>
      <v-btn large block color="primary" @click="loginUser" class="mt-auto">
        <strong>{{ $t("site.access.login") }}</strong>
      </v-btn>
    </v-card-actions>
    <v-divider class="pa-0 my-0" />
    <v-card-text class="pa-0">
      {{ versionLabel }} <span v-html="'&copy;'" /> MannieDev
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { loginUser } from "@/helpers/router-helpers";
import { appName } from "@/model/config";
import { colorMainBackground } from "@/plugins/vuetify";
import authModule from "@/store/modules/auth-module";
import { LoginUser } from "@taskmanagement/taskapp-model";
import Vue from "vue";

export default Vue.extend({
  name: "LoginFormComponent",
  data: () => ({
    appName: appName,
    user: new LoginUser(),
    showPasswordText: false,
  }),
  // props: {
  //   width: { type: Number },
  //   maxWidth: { type: Number },
  //   minWidth: { type: Number },
  // },
  computed: {
    loading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
    passwordIcon() {
      if (!this.user.password) {
        return "";
      }
      return this.showPasswordText ? "mdi-eye-outline" : "mdi-eye-off-outline";
    },
    backgroundColor() {
      return colorMainBackground;
    },
    versionLabel() {
      return appName + "  " + this.$store.state.version;
    },
  },
  methods: {
    loginUser() {
      this.loading = true;
      loginUser(this.user).finally(() => {
        this.loading = false;
        if (authModule.isLoggedIn) {
          window.location.reload();
        }
      });
    },
  },
});
</script>

<style></style>

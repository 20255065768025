<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" md="9">
        <v-card>
          <detailOverviewComponent
            :title="$t('object.task.taskTitle')"
            v-model="items"
            :ID="order?.ticketNumber"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-container class="pa-0 mb-2">
          <v-container outlined class="pa-0">
            <v-list subheader three-line class="text-start">
              <v-card flat class="pa-2">
                <strong>{{ $t("object.misc.technicianHistory") }}</strong>
              </v-card>
              <v-divider />

              <v-list-item v-for="(item, i) in allocations" :key="i">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-container
                      class="pa-0 d-flex align-center justify-space-between"
                    >
                      <strong> {{ item?.allocatedTo?.fullName }} </strong>

                      <statusDisplayComponent
                        v-if="item"
                        :color="taskStage(item)?.color"
                        :text="taskStage(item)?.value"
                        outlined
                      />
                    </v-container>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-container class="pa-0">
                      <strong
                        class="pa-0 primary--text"
                        v-if="!!item?.agentComment"
                      >
                        <v-icon color="primary">mdi-note-text-outline</v-icon>
                        {{ $t("object.misc.comment") }}
                      </strong>
                      {{ item?.agentComment }}
                    </v-container>
                    <v-list-item-subtitle>
                      <span>
                        {{ formatTime(item.updatedAt) }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-container>
          <v-card class="pa-0 mt-2">
            <v-expansion-panels accordion flat>
              <v-expansion-panel v-if="invoice?.invoiceNumber">
                <v-expansion-panel-header>
                  <strong>{{ $t("object.invoice.invInfo") }}</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat class="pa-0">
                    <v-btn
                      color="primary"
                      absolute
                      right
                      top
                      plain
                      icon
                      @click="goToInvoice(invoice)"
                    >
                      <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
                    </v-btn>
                    <v-row
                      dense
                      v-for="(inv, i) in invoiceInformation"
                      :key="i"
                    >
                      <v-col cols="12" md="4" align="start">
                        {{ inv?.title }}
                      </v-col>
                      <v-col cols="12" md="8" align="start">
                        <strong> {{ inv?.value }} </strong>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {
  formatAmount,
  formatTime,
  getFullName,
} from "@/helpers/component-helper";
import {
  DefaultVueType,
  InformationType,
  TypeCardOverview,
  TypeStageFormatter,
} from "@/model";
import { ROUTE_INVOICEDETAILS } from "@/model/routes";
import formatterModule from "@/store/modules/formatter-module";
import {
  Allocation,
  EnumTaskStage,
  Invoice,
  Person,
  Task,
} from "@taskmanagement/taskapp-model";
import Vue from "vue";
import DetailOverviewComponent from "../detail/detailoverview-component.vue";
import StatusDisplayComponent from "../statusdisplay-component.vue";

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "OrderDetailComponent",
  components: {
    detailOverviewComponent: DetailOverviewComponent,
    statusDisplayComponent: StatusDisplayComponent,
  },
  data: () => ({}),
  props: {
    order: { type: Task },
  },
  computed: {
    items() {
      const item = this.order;
      return [
        {
          cardTitle: this.$i18n.t("object.misc.detailsName"),
          rowItems: [
            {
              icon: "mdi-tag-outline",
              title: item?.category?.name ?? "",
              subTitle: item?.subCategory?.name ?? "",
              headerText: this.$t("object.serviceProvider.category"),
            },
            {
              icon: "mdi-cash-multiple",
              title: item?.price ? formatAmount(item?.price) : null,
              headerText: this.$t("object.task.servicePrice"),
            },
          ],
        },
        {
          cardTitle: this.$t("object.misc.schedule"),
          rowItems: [
            {
              icon: "mdi-calendar-clock-outline",
              title: `${formatTime(item?.startTime as string) ?? ""}`,
              subTitle: `${formatTime(item?.endTime as string) ?? ""}`,
              headerText: this.$t("object.misc.dateTime"),
            },
            {
              icon: "mdi-map-marker-outline",
              title: `${item?.streetNo ?? ""} ${item?.street ?? ""}`,
              subTitle: `${item?.postalCode ?? ""} ${item?.city ?? ""} ${
                item?.country ?? ""
              }`,
              headerText: this.$i18n.t("object.person.address"),
            },
          ],
        },
        {
          cardTitle: this.$i18n.t("object.misc.clientName"),
          rowItems: [
            {
              icon: "mdi-account-outline",
              title: `${item?.requesterFirstName ?? ""} ${
                item?.requesterLastName ?? ""
              }`,
              subTitle: null,
              headerText: this.$i18n.t("object.misc.name"),
            },
            {
              icon: "mdi-phone-outline",
              title: `${item?.requesterEmail}`,
              subTitle: `${item?.requesterPhoneNo}`,
              headerText: this.$t("object.person.contact"),
            },
          ],
        },
        {
          cardTitle: this.$i18n.t("object.misc.technician"),
          rowItems: [
            {
              icon: "mdi-account-outline",
              title: `${item?.allocatedTo?.firstName} ${item?.allocatedTo?.lastName}`,
              subTitle: "",
              headerText: this.$t("object.misc.name"),
            },
            {
              icon: "mdi-phone-outline",
              title: `${item?.allocatedTo?.email}`,
              subTitle: `${item?.allocatedTo?.phoneNumber}`,
              headerText: this.$t("object.person.contact"),
            },
          ],
        },
      ] as TypeCardOverview[];
    },
    allocations() {
      return this.order.allocation ?? [];
    },
    invoiceInformation() {
      const inv = this.order.invoice;
      const invAmt = this.order.invoicedAmount;
      return [
        {
          title: this.$i18n.t("object.invoice.invNumber"),
          value: inv?.invoiceNumber,
        },
        {
          title: this.$i18n.t("object.invoice.invoiceDate"),
          value: inv?.invoiceDate ? this.$d(inv?.invoiceDate) : null,
        },
        {
          title: this.$i18n.t("object.invoice.invoiceAmount"),
          value: invAmt ? this.$n(invAmt) : null,
        },
      ];
    },
    invoice() {
      return this.order.invoice ?? new Invoice();
    },
  },
  methods: {
    getFullName(sp: Person) {
      return getFullName(sp) ?? "";
    },
    taskStage(task: Allocation) {
      return (
        formatterModule.taskStage.find((x) => x.enum === task.stage) ?? null
      );
    },
    goToInvoice(invoice) {
      if (invoice && invoice.invoiceNumber) {
        this.$router.push({
          name: ROUTE_INVOICEDETAILS,
          params: { invoiceNumber: invoice.invoiceNumber },
        });
      }
    },
    formatTime(string: string | Date | undefined) {
      return string ? formatTime(string.toString()) : "";
    },
  },
});

type PropType = { order: Task };
type MethodType = {
  getFullName(sp: Person): string;
  taskStage(task: Allocation): TypeStageFormatter<EnumTaskStage> | null;
  goToInvoice(invoice: Invoice): void;
  formatTime(string?: string | Date): string;
};
type ComputedType = {
  items: TypeCardOverview[];
  allocations: Allocation[];
  invoiceInformation: InformationType[];
  invoice: Invoice;
};
type DataType = DefaultVueType;
</script>

<style></style>
